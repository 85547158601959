import axios from '@/libs/axios'

class DataService {
  getAll() {
    return axios.get("/services");
  }
  get(id) {
    return axios.get(`/services/${id}`);
  }
  create(data) {
    return axios.post("/services", data);
  }
  update(id, data) {
    return axios.put(`/services/${id}`, data);
  }
  delete(id) {
    return axios.delete(`/services/${id}`);
  }

}
export default new DataService();